(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:MultiWalletCardItemListCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('MultiWalletCardItemListCtrl', MultiWalletCardItemListCtrl);

  function MultiWalletCardItemListCtrl(FormControl,
    _,
    $q,
    $mdDialog,
    $mdToast,
    $document,
    orderType,
    LoggedUser,
    $scope,
    $rootScope,
    $translate,
    ItemList,
    DataReconciliation) {
    var vm = this
      , formControl = new FormControl()
      , multiWalletItems = 'multiWalletItems'
      , itemList = new ItemList();

    $scope.$on('$stateChangeSuccess', onStateChangeSuccess);

    vm.getItems = function getItems() {
      return itemList.getItemListStorage(multiWalletItems);
    };

    vm.items = vm.getItems();
    itemList.setItems(vm.items);
    vm.itemListForm = {};
    vm.orderType = orderType;

    vm.refreshItemList = function refreshItemList() {
      itemList.setItemListStorage(multiWalletItems);
      vm.items = vm.getItems();
    };

    vm.getSize = function getSize() {
      return itemList.getSize();
    };

    vm.addItem = function addItem($event, item) {
      itemList.addItem(item);
      vm.refreshItemList();
    };

    vm.updateItem = function updateItem($event, item) {
      itemList.updateItem(item);
      vm.refreshItemList();
    };

    vm.deleteItem = function deleteItem(item) {
      itemList.deleteItem(item);
    };

    $rootScope.$on('itemList.addNewItem', vm.addItem);
    $rootScope.$on('itemList.updateItem', vm.updateItem);

    vm.openItemDialog = function openItemDialog(index) {
      var item = _.defaultsDeep({}, vm.items[index]) || {};
      $mdDialog.show({
        controller: 'MultiWalletCardItemDialogCtrl',
        controllerAs: 'multiWalletCardItemDialog',
        templateUrl: 'wb-order/views/multi-wallet-card-item-dialog.tpl.html',
        bindToController: true,
        locals: {
          size: vm.getSize(),
          item: item,
          items: vm.items,
          orderType: orderType
        },
        parent: angular.element($document.body),
        focusOnOpen: false
      });
    };

    vm.editItem = function editItem(index) {
      vm.openItemDialog(index);
    };

    vm.openDeleteItemDialog = function openDeleteItemDialog(item) {
      var confirm = $mdDialog.confirm({ focusOnOpen: false })
        .parent(angular.element($document.body))
        .content($translate.instant('order.itemDialog.confirmDelete'))
        .ok($translate.instant('order.itemDialog.delete'))
        .cancel($translate.instant('order.itemDialog.cancel'));

      $mdDialog
        .show(confirm)
        .then(function () {
          vm.deleteItem(item);
          vm.refreshItemList();
          $mdToast.showToast('order.unique.toast.delete');
        });
    };

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    function onStateChangeSuccess() {
      $mdDialog.show({
        controller: 'DataReconciliationProcessDialogCtrl',
        controllerAs: 'dataReconciliationProcessDialog',
        templateUrl: 'wb-order/views/data-reconciliation-process-dialog.tpl.html',
        bindToController: true,
        parent: angular.element($document.body),
        clickOutsideToClose: true,
        focusOnOpen: false
      });
    }

    vm.submit = function submit() {
      formControl.process(DataReconciliation.validateDataReconciliations(vm.items).$promise)
        .then(function () {
          $scope.$emit('order.next', 'contract', 'multiWalletCardItemList');
        });
    };
  }
}());
